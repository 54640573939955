export const seriesList = [
        "World of Outlaws Pro Qualifying Series - 2024 Season",
        "Winter iRacing NASCAR Series - Fixed - 2024 Season",
        "Winter iRacing NASCAR Series - 2024 Season",
        "Global Mazda MX-5 Cup by Fanatec - 2025 Season 1 Fixed",
        "BMW M2 Cup - 2025 Season 1 - Fixed",
        "GR Buttkicker Cup - 2025 Season 1 - Fixed",
        "Legends Road Cup by Go Motorsports Shop - 2025 Season 1 ",
        "Clio Cup - 2025 Season 1 - Fixed",
        "Production Car Challenge by Sim-Lab - 2025 Season 1",
        "Ferrari GT3 Challenge - 2025 Season 1 - Fixed",
        "Rain Master - 2025 Season 1 - Fixed",
        "Ring Meister by Ricmotech - 2025 Season 1",
        "Mustang Skip Barber  Challenge - 2025 Season 1 - Fixed",
        "Mission R Challenge - 2025 Season 1 - Fixed",
        "BMW M Power Challenge - 2025 Season 1 - Fixed",
        "Spec Racer Ford Challenge - 2025 Season 1",
        "Global Sports Car Challenge by Fanatec - 2025 Season 1 - Fixed",
        "TCR Virtual Challenge - Fixed - 2025 Season 1",
        "TCR Virtual Challenge - 2025 Season 1",
        "GT4 Falken Tyre Challenge - 2025 Season 1 Fixed",
        "Sports Car Challenge by Falken Tyre - 2025 Season 1",
        "LMP3 Trophy - 2025 Season 1",
        "IMSA Michelin Pilot Challenge - 2025 Season 1",
        "Radical Esports Cup - 2025 Season 1",
        "IMSA Vintage Series - 2025 Season 1",
        "Stock Car Brasil - 2025 Season 1 - Fixed",
        "Advanced Mazda MX-5 Cup - 2025 Season 1",
        "Supercars Series - 2025 Season 1",
        "Supercars Series - Australian Servers - 2025 Season 1",
        "iRacing Porsche Cup - 2025 Season 1",
        "iRacing Porsche Cup  - Fixed - 2025 Season 1",
        "Proto-GT Challenge by Thrustmaster 2025 Season 1",
        "GT Endurance Series by Simucube - 2025 Season 1",
        "GT Sprint Series by Simucube - 2025 Season 1",
        "GT3 Challenge Fixed by Fanatec - 2025 Season 1 - Fixed",
        "GTE Sprint Series by CONSPIT  - 2025 Season 1",
        "Global Endurance Tour by CONSPIT - 2025 Season 1",
        "Prototype Challenge  - 2025 Season 1 - Fixed",
        "IMSA Endurance Series - 2025 Season 1",
        "IMSA iRacing Series - 2025 Season 1",
        "IMSA iRacing Series - Fixed - 2025 Season 1",
        "Formula Vee Series by trophi.ai - 2025 Season 1 Fixed",
        "Formula 1600 Rookie Sim-Motion Series - 2025 Season 1 Fixed",
        "US Open Wheel D - USF 2000 Series  - 2025 Season 1 - Fixed",
        "Jr Open Wheel Oval Challenge - 2025 Season 1",
        "Motorsport UK FF 1600 Trophy by Thrustmaster - 2025 Season 1",
        "FIA Formula 4 Challenge- 2025 Season 1 - Fixed",
        "FIA Formula 4 Challenge - 2025 Season 1",
        "Skip Barber Race Series - 2025 Season 1",
        "FIA F4 Esports Regional Tour  - Asia Pacific - 2025 Season 1",
        "FIA F4 Esports Regional Tour  - Americas - 2025 Season 1",
        "FIA F4 Esports Regional Tour - Europe - 2025 Season 1",
        "Dallara Formula iR - 2025 Season 1",
        "Formula C -  Dallara F3 Series - 2025 Season 1",
        "Formula C - Thrustmaster Dallara F3 Series -2025 Season 1-Fixed",
        "Formula C - Super Formula Lights - 2025 Season 1",
        "Formula C - Super Formula Lights - Fixed - 2025 Season 1",
        "US Open Wheel C - Indy Pro 2000 Series - 2025 Season 1",
        "Lotus 49 Grand Prix Legends - 2025 Season 1",
        "iRacing Lotus 79 Grand Prix Series- 2025 Season 1",
        "INDYCAR Series - Oval - Fixed - 2025 Season 1",
        "INDYCAR Series - Fixed - 2025 Season 1",
        "INDYCAR Series - 2025 Season 1",
        "Formula B - Super Formula Series 2025 Season 1",
        "Formula B - Super Formula Series - Fixed 2025 Season 1",
        "Formula A - Grand Prix Series - 2025 Season 1",
        "Formula A - Grand Prix Series - Fixed - 2025 Season 1",
        "Dallara DW12 Dash - 2025 Season 1",
        "NASCAR Pickup Cup - 2025 Season 1",
        "NASCAR Carburetor Cup - 2025 Season 1",
        "Rookie Legends Cup by Simshop 2025 Season 1",
        "Mini Stock Rookie Series by Thrustmaster - 2025 Season 1",
        "Rookie Street Stock Series by Fanatec - 2025 Season 1 Fixed",
        "CARS Late Model Stock Series - 2025 Season 1",
        "CARS Late Model Stock Series - Fixed - 2025 Season 1",
        "ARCA Menards Series - 2025 Season 1 - Fixed",
        "SRX Series - Fixed - 2025 Season 1",
        "SK Modified Weekly Series - 2025 Season 1",
        "SK Modified Series - 2025 Season 1 - Fixed",
        "Draft Master Challenge by Simagic 2025 Season 1",
        "Street Stock Series by Next Level Racing - 2025 Season 1",
        "NASCAR Tour Modified Series - 2025 Season 1",
        "NASCAR Tour Modified Series - Fixed - 2025 Season 1 - Fixed",
        "Legends Cup by Go Motorsports Shop - 2025 Season 1",
        "Super Late Model Series - 2025 Season 1",
        "Super Late Model Series - Fixed - 2025 Season 1 - Fixed",
        "NASCAR Class C Maconi Series - Fixed - 2025 Season 1",
        "NASCAR Gen 4 Cup Series - 2025 Season 1 Fixed",
        "NASCAR 1987 Legends Cup  - 2025 Season 1 Fixed",
        "NASCAR Xfinity Series - Fixed - 2025 Season 1 Fixed ",
        "Silver Crown Cup - 2025 Season 1",
        "Sprint Car Cup - 2025 Season 1",
        "NASCAR Class A Series - 2025 Season 1",
        "NASCAR Class A Series - Fixed - 2025 Season 1 Fixed",
        "Dirt Mini Stock Rookie Series 2025 Season 1",
        "DIRTcar Street Stock Rookie Racing Series by MOZA 2025 Season 1",
        "Micro Winged Sprint Car Rookie Series - 2025 Season 1",
        "Micro Non Wing Sprint Car Rookie Series - 2025 Season 1",
        "Dirt Legends Cup - 2025 Season 1",
        "DIRTcar 305 Sprint Car Series by Fanatec - 2025 Season 1 Fixed",
        "DIRTcar Limited Late Model Series - 2025 Season 1 Fixed",
        "DIRTcar 358 Modified Series 2025 Season 1",
        "Outlaw Micro Showdown 2025 Season 1",
        "DIRTcar Class C Dirt Street Stock Series - 2025 Season 1 Fixed",
        "Dirt Midget Cup - 2025 Season 1",
        "Dirt Midget Cup - Fixed - 2025 Season 1",
        "DIRTcar 360 Sprint Car Series 2025 Season 1",
        "DIRTcar 360 Sprint Car Series - Fixed 2025 Season 1",
        "USAC 360 Sprint Car Series - 2025 Season 1",
        "DIRTcar Pro Late Model Series - 2025 Season 1",
        "DIRTcar Pro Late Model Series - Fixed - 2025 Season 1",
        "DIRTcar UMP Modified Series - 2025 Season 1 - Fixed",
        "Big Block Modified Series - 2025 Season 1",
        "Big Block Modified Series - Fixed - 2025 Season 1",
        "World of Outlaws Sprint Car Series - 2025 Season 1",
        "World of Outlaws Sprint Car Series - 2025 Season 1 - Fixed",
        "Dirt 410 Sprint Car Tour - 2025 Season 1",
        "AMSOIL USAC Sprint Car 2025 - Season 1",
        "World of Outlaws Late Model Series - 2025 Season 1",
        "World of Outlaws Late Model Series - 2025 Season 1 - Fixed",
        "Dirt Super Late Model Tour - 2025 Season 1",
        "Volkswagen Beetle Lite iRX Rookie Series - 2025 Season 1",
        "Rallycross Series - 2025 Season 1",
        "iRX Volkswagen Beetle Lite Series - 2025 Season 1",
        "Rallycross Series - Fixed - 2025 Season 1",
        "Pro 2 Lite Off-Road Rookie Racing Series - 2025 Season 1",
        "Pro 4 Off-Road Racing Series - Fixed - 2025 Season 1",
        "Pro 2 Off-Road Racing Series - Fixed - 2025 Season 1",
        "Pro 4 Off-Road Racing Series - 2025 Season 1",
        "Pro 2 Off-Road Racing Series - 2025 Season 1",
        "NASCAR iRacing Class B Series Series - 2025 Season 1",
        "NASCAR iRacing Class C Series Series - 2025 Season 1",
        "IMSA Sportscar Endurance Challenge - 2025 Season 1",
].sort();
