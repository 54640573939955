import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import "../styles/CareerStats.css";
import ui_id_img from "../assets/ui_id.png";
import url_id_img from "../assets/url_id.png";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Plot from "react-plotly.js"; // Import Plotly

function CareerStats() {
        const { custid } = useParams();
        const [data, setData] = useState(null);
        const [error, setError] = useState(null);
        const [selectedCategories, setSelectedCategories] = useState(["road", "sports_car", "formula_car", "oval", "dirt_road", "dirt_oval"]);
        const [percentilesData, setPercentilesData] = useState(null);
        const [shouldRetry, setShouldRetry] = useState(false); // New state to track if we should retry
        const [isModalOpen, setModalOpen] = useState(false);
        const [cust_id, setCustId] = useState("");
        const navigate = useNavigate();

        const handleModalOpen = () => {
                setModalOpen(true);
        };

        const handleModalClose = () => {
                setModalOpen(false);
        };

        const handleInputChange = (event) => {
                setCustId(event.target.value);
        };

        const handleGoButtonClick = () => {
                if (cust_id.length >= 4 && !isNaN(cust_id)) {
                        navigate(`/user/careerstats/${cust_id}`);
                } else {
                        alert("Please enter a valid Customer ID");
                }
        };

        const handleExamples = (cust_id) => {
                navigate(`/user/careerstats/${cust_id}`);
        };

        async function requestCustUpdate(cust_id) {
                try {
                        const response = await fetch(`https://iracing6-backend.herokuapp.com/api/member-career-stats/rescan/${cust_id}`, {
                                method: "POST",
                                headers: {
                                        "Content-Type": "application/json",
                                },
                        });

                        const data = await response.json();

                        if (response.status === 200) {
                                return data;
                        } else {
                                console.error(data.error);
                                throw new Error(data.error);
                        }
                } catch (error) {
                        console.error("Error requesting cust update:", error);
                        throw error;
                }
        }

        const handleRequestUpdate = async () => {
                try {
                        const response = await requestCustUpdate(custid);
                        if (response && response.message) {
                                // Trigger the fetch again to refresh the displayed data
                                setShouldRetry(true);
                        }
                } catch (error) {
                        // Handle any errors from the requestCustUpdate function
                        alert("Error updating data: " + error.message);
                }
        };

        let lastUpdatedRelativeTime = "Loading..."; // Default text or whatever you prefer
        if (data) {
                lastUpdatedRelativeTime = moment(data.last_update).fromNow();
        }

        const retryTimeoutRef = useRef(null); // Ref to store the timeout

        const Modal = ({ isOpen, onClose, children }) => {
                if (!isOpen) return null;

                return (
                        <div className="modal-overlay">
                                <div className="modal-content">
                                        {children}
                                        <button className="modal_close_button" onClick={onClose}>
                                                Close
                                        </button>
                                </div>
                        </div>
                );
        };

        // Function to check if chart data exists
        const checkAndTriggerRescan = (data) => {
                const hasData = selectedCategories.some((category) => data[category]?.iRating_chart?.data?.length);
                if (!hasData) {
                        handleRequestUpdate();
                } else {
                        setShouldRetry(true);
                }
        };

        // Fetch career stats data
        useEffect(() => {
                if (custid) {
                        fetch(`https://iracing6-backend.herokuapp.com/api/member-career-stats/career/${custid}`)
                                .then((response) => {
                                        if (!response.ok) {
                                                if (response.status === 500 || response.status === 202) {
                                                        throw new Error("You have been added to the queue to be scanned.");
                                                }
                                                throw new Error("Something went wrong.");
                                        }
                                        return response.json();
                                })
                                .then((data) => {
                                        setData(data);
                                        checkAndTriggerRescan(data); // Check and trigger rescan if necessary
                                        setError(null); // Clear the error state
                                        setShouldRetry(false); // Reset shouldRetry to false
                                })
                                .catch((err) => {
                                        setError(err.message);
                                        if (err) {
                                                if (!shouldRetry) {
                                                        setShouldRetry(true);
                                                }
                                        }
                                });
                }

                return () => {
                        clearTimeout(retryTimeoutRef.current); // Clear the timeout when the component is unmounted or before starting a new fetch
                };
        }, [custid, shouldRetry, selectedCategories]);

        // Handle retry mechanism
        useEffect(() => {
                if (shouldRetry) {
                        retryTimeoutRef.current = setTimeout(() => {
                                setShouldRetry(false); // Reset shouldRetry to trigger the fetch again
                        }, 15000); // Retry after 15 seconds
                }

                return () => {
                        clearTimeout(retryTimeoutRef.current); // Clear the timeout when the component is unmounted
                };
        }, [shouldRetry]);

        // Fetch percentiles data
        useEffect(() => {
                setPercentilesData(null);
                // Adjusted API call to fetch percentiles for all categories
                fetch(
                        `https://iracing6-backend.herokuapp.com/api/user-career-stats-percentiles/?field=wins,starts,laps,laps_led,laps_led_percentage,starts_per_win,avg_incidents&category=${selectedCategories.join(
                                ","
                        )}`
                )
                        .then((response) => response.json())
                        .then((data) => {
                                setPercentilesData(data);
                        })
                        .catch((error) => console.error("Error fetching percentiles:", error));
        }, [selectedCategories]);

        const calculatePercentile = (userValue, sortedData) => {
                const rank = sortedData.filter((val) => val <= userValue).length;
                return (rank / sortedData.length) * 100;
        };

        const calculatePercentileForLowerIsBetter = (userValue, sortedData) => {
                const rank = sortedData.filter((val) => val >= userValue).length;
                return (rank / sortedData.length) * 100;
        };

        // Ensure data and the specific category properties exist before rendering
        if (data) {
                // Define colors for each category
                const categoryColors = {
                        road: "#1f77b4", // Blue
                        sports_car: "#ff7f0e", // Orange
                        formula_car: "#2ca02c", // Green
                        oval: "#d62728", // Red
                        dirt_road: "#9467bd", // Purple
                        dirt_oval: "#8c564b", // Bronze
                };

                // Prepare traces for selected categories
                const traces = selectedCategories
                        .map((category) => {
                                if (data[category] && data[category].iRating_chart && Array.isArray(data[category].iRating_chart.data)) {
                                        const chartData = data[category].iRating_chart.data;
                                        return {
                                                x: chartData.map((item) => item.when),
                                                y: chartData.map((item) => item.value),
                                                type: "scatter",
                                                mode: "lines+markers",
                                                name: category.replace("_", " ").replace(/\b\w/g, (l) => l.toUpperCase()), // Format category name
                                                marker: {
                                                        size: 4,
                                                        color: categoryColors[category],
                                                        line: {
                                                                color: categoryColors[category],
                                                                width: 1,
                                                        },
                                                },
                                        };
                                } else {
                                        return null;
                                }
                        })
                        .filter((trace) => trace !== null);

                return (
                        <div className="career_stats_container">
                                <div className="cs_title_hero">{data.display_name}</div>
                                <div className="cs_career_section_container">
                                        {/* <div className="big_user_box_cs">
                                                <div className="cs_user_name_div">{data.display_name}</div>
                                                <div className="cs_user_id_div">#{custid}</div>
                                        </div> */}

                                        <div className="cs_career_stats_row_container">
                                                <div className="cs_career_stats_row_top">
                                                        {data.formula_car && data.formula_car.iRating ? (
                                                                <div className="cs_career_stats_row_cat_box">
                                                                        <div className="cs_career_stats_row_title">Formula</div>
                                                                        <div className="cs_career_stats_row_value">{data.formula_car.iRating.value}</div>
                                                                </div>
                                                        ) : null}
                                                        {data.sports_car && data.sports_car.iRating ? (
                                                                <div className="cs_career_stats_row_cat_box">
                                                                        <div className="cs_career_stats_row_title">Sports Car</div>
                                                                        <div className="cs_career_stats_row_value">{data.sports_car.iRating.value}</div>
                                                                </div>
                                                        ) : null}
                                                        {!data.formula_car ? (
                                                                <div className="cs_career_stats_row_cat_box">
                                                                        <div className="cs_career_stats_row_title">Road</div>
                                                                        <div className="cs_career_stats_row_value">{data.road.iRating.value}</div>
                                                                </div>
                                                        ) : null}
                                                        {!data.oval ? (
                                                                <div className="cs_career_stats_row_cat_box">
                                                                        <div className="cs_career_stats_row_title">Oval</div>
                                                                        <div className="cs_career_stats_row_value">{data.oval.iRating.value}</div>
                                                                </div>
                                                        ) : null}
                                                </div>
                                                <div className="cs_career_stats_row_bottom">
                                                        <div className="cs_career_stats_row_cat_box">
                                                                <div className="cs_career_stats_row_title">Dirt Road</div>
                                                                <div className="cs_career_stats_row_value">{data.dirt_road && data.dirt_road.iRating ? data.dirt_road.iRating.value : 0}</div>
                                                        </div>
                                                        <div className="cs_career_stats_row_cat_box">
                                                                <div className="cs_career_stats_row_title">Dirt Oval</div>
                                                                <div className="cs_career_stats_row_value">{data.dirt_oval && data.dirt_oval.iRating ? data.dirt_oval.iRating.value : 0}</div>
                                                        </div>
                                                </div>
                                        </div>
                                </div>
                                {/* Render Plotly graph */}
                                <div className="chart-container">
                                        {/* Category Toggle Buttons */}
                                        <div className="chart-cat-buttons-cs-container">
                                                {["road", "sports_car", "formula_car", "oval", "dirt_road", "dirt_oval"].map((category, index) => (
                                                        <button
                                                                key={category}
                                                                className={`chart-cat-buttons-cs-div ${
                                                                        index === 0 ? "chart-cat-buttons-cs-div-le" : index === 5 ? "chart-cat-buttons-cs-div-re" : ""
                                                                } ${selectedCategories.includes(category) ? "active-category" : ""}`}
                                                                onClick={() => handleCategoryToggle(category)}
                                                        >
                                                                {category.replace("_", " ").replace(/\b\w/g, (l) => l.toUpperCase())}
                                                        </button>
                                                ))}
                                        </div>
                                        {traces.length > 0 ? (
                                                <Plot
                                                        data={traces}
                                                        layout={{
                                                                // title: `iRating (${selectedCategories.map((cat) => cat.replace("_", " ").replace(/\b\w/g, (l) => l.toUpperCase())).join(", ")})`,
                                                                xaxis: {
                                                                        gridcolor: "#444",
                                                                        zerolinecolor: "#444",
                                                                        linecolor: "#cfcfcf",
                                                                        tickfont: {
                                                                                color: "#cfcfcf",
                                                                        },
                                                                        gridcolor: "#444",
                                                                        zerolinecolor: "#444",
                                                                        linecolor: "#cfcfcf",
                                                                        tickfont: {
                                                                                color: "#cfcfcf",
                                                                        },
                                                                },
                                                                yaxis: {
                                                                        title:
                                                                                window.innerWidth > 800
                                                                                        ? {
                                                                                                  text: "iRating",
                                                                                                  standoff: 14,
                                                                                          }
                                                                                        : {
                                                                                                  text: "iRating",
                                                                                                  standoff: 30,
                                                                                          },

                                                                        gridcolor: "#444",
                                                                        zerolinecolor: "#444",
                                                                        linecolor: "#cfcfcf",
                                                                        tickfont: {
                                                                                color: "#cfcfcf",
                                                                        },
                                                                },
                                                                width: window.innerWidth > 800 ? 800 : window.innerWidth - 20,
                                                                height: window.innerWidth > 800 ? 300 : 300,
                                                                margin: window.innerWidth > 800 ? { l: 80, r: 30, t: 0, b: 0 } : { l: 50, r: 20, t: 0, b: 40 },
                                                                autosize: false,
                                                                plot_bgcolor: "#25242700",
                                                                paper_bgcolor: "#25242700",
                                                                font: {
                                                                        color: "#cfcfcf",
                                                                        family: "Questrial, sans-serif",
                                                                },
                                                                legend: {
                                                                        orientation: "h",
                                                                        y: window.innerWidth > 800 ? -0.1 : -0.25,
                                                                },
                                                        }}
                                                        config={{ displayModeBar: false }}
                                                />
                                        ) : (
                                                <div>No data available for the selected categories.</div>
                                        )}
                                </div>
                                {/* <div className="top-separator-div-cs"> </div> */}

                                {/* Statistics Section */}
                                {selectedCategories.map((Category) => (
                                        <div key={Category} className="cs_category_section_container">
                                                <h3 className="category-heading">{Category.replace("_", " ").replace(/\b\w/g, (l) => l.toUpperCase())} Statistics</h3>
                                                <div className="cs_category_stats_row">
                                                        <StatBox title="iRating Starts" value={data[Category].starts} percentileData={percentilesData?.[Category]?.starts} />
                                                        <StatBox title="Wins" value={data[Category].wins} percentileData={percentilesData?.[Category]?.wins} />
                                                        <StatBox
                                                                title="Starts per Win"
                                                                value={(data[Category].starts / data[Category].wins).toFixed(2)}
                                                                percentileData={percentilesData?.[Category]?.starts_per_win}
                                                                isLowerBetter
                                                        />
                                                </div>
                                                <div className="cs_category_stats_row">
                                                        <StatBox title="Laps Led" value={data[Category].laps_led} percentileData={percentilesData?.[Category]?.laps_led} />
                                                        <StatBox
                                                                title="Laps Led Percentage"
                                                                value={data[Category].laps_led_percentage}
                                                                percentileData={percentilesData?.[Category]?.laps_led_percentage}
                                                        />
                                                        <StatBox
                                                                title="Avg Incidents"
                                                                value={data[Category].avg_incidents.toFixed(2)}
                                                                percentileData={percentilesData?.[Category]?.avg_incidents}
                                                                isLowerBetter
                                                        />
                                                </div>
                                                <div className="cs_small_stats_container">
                                                        <div className="cs_small_stats_box">
                                                                <div className="cs_small_stats_title">Total Laps</div>
                                                                <div className="cs_small_stats_value">{data[Category].laps}</div>
                                                        </div>
                                                        <div className="cs_small_stats_box">
                                                                <div className="cs_small_stats_title">Poles</div>
                                                                <div className="cs_small_stats_value">{data[Category].poles}</div>
                                                        </div>
                                                        <div className="cs_small_stats_box">
                                                                <div className="cs_small_stats_title">Top 5s</div>
                                                                <div className="cs_small_stats_value">{data[Category].top5}</div>
                                                        </div>
                                                </div>
                                        </div>
                                ))}
                                <div className="cs_last_updated_container">
                                        <div className="cs_last_updated_text">Last updated: {lastUpdatedRelativeTime}</div>
                                        <div className="cs_last_updated_request" onClick={handleRequestUpdate}>
                                                Request Update
                                        </div>
                                </div>
                        </div>
                );

                function StatBox({ title, value, percentileData, isLowerBetter = false }) {
                        const getPercentileColor = (percentile) => {
                                if (percentile <= 49) return "#6e6e6ee8";
                                if (percentile <= 74) return "#15a108e8";
                                if (percentile <= 89) return "#13498fe8";
                                if (percentile <= 99) return "#8f0edae8";
                                return "#eb7c14da";
                        };

                        const getTopPercentile = (percentile) => {
                                return 100 - percentile;
                        };

                        if (!percentileData) {
                                return (
                                        <div className="cs_category_stats_statbox">
                                                <div className="cs_category_stats_statbox_title">{title}</div>
                                                <div className="cs_category_stats_statbox_value">{value}</div>
                                                <div className="cs_cat_perc_box" style={{ backgroundColor: "grey" }}>
                                                        Loading...
                                                </div>
                                        </div>
                                );
                        }

                        const { percentiles, leaderboard } = percentileData;
                        let percentileValue = "Loading...";
                        let color = "grey"; // default color for when data is loading
                        let ranking;
                        let topPercentile;

                        if (percentileData) {
                                const calculatedPercentile = isLowerBetter ? calculatePercentileForLowerIsBetter(value, percentiles) : calculatePercentile(value, percentiles);
                                topPercentile = getTopPercentile(calculatedPercentile).toFixed(0); // Calculate "top x %" value

                                if (calculatedPercentile > 98 && leaderboard) {
                                        // If the user is at the 100th percentile, find their rank on the leaderboard
                                        // ranking = getRanking(value, leaderboard);
                                }

                                percentileValue = `Top ${topPercentile}%`; // Change to display "Top x %"
                                color = getPercentileColor(calculatedPercentile);
                        }

                        return (
                                <div className="cs_category_stats_statbox">
                                        <div className="cs_category_stats_statbox_title">{title}</div>
                                        <div className="cs_category_stats_statbox_value">{value}</div>
                                        <div className="cs_cat_perc_box" style={{ backgroundColor: color }}>
                                                {ranking ? (
                                                        <div className="cs_category_stats_statbox_ranking">Rank: {ranking}</div>
                                                ) : (
                                                        <div className="cs_category_stats_statbox_percvalue">{percentileValue}</div>
                                                )}
                                        </div>
                                </div>
                        );
                }
        } else if (error === "You have been added to the queue to be scanned.") {
                return (
                        <div className="cs_queue_container_div_meta">
                                <div className="cs_queue_container_div">
                                        <div className="queue_title">We don't have this user in our database.</div>
                                        <div>You have been queued for a scan.</div>
                                        <div className="min_warn">This can take up to 2 minutes</div>
                                </div>
                                <div className="cs_queue_container_div_dw">
                                        <p>This page will refresh automatically</p>
                                </div>
                                <div className="spinner"></div>
                        </div>
                );
        } else if (error) {
                return (
                        <div className="whitetext">
                                <h1>Error</h1>
                                <p>{error}</p>
                                <p>Please email me at contact@iracingdata.com with this error message. Thank you.</p>
                        </div>
                );
        } else if (!custid) {
                return (
                        <div className="no_cust_id_container">
                                <div className="top-background-div">
                                        <div className="top-background-text-cs">Career Stats</div>
                                        <div className="cust_id_input_div">
                                                <div className="input-container-row">
                                                        <input
                                                                className="cust_id_input"
                                                                type="text"
                                                                placeholder="iRacing Customer ID"
                                                                name="cust_id"
                                                                value={cust_id}
                                                                onChange={handleInputChange}
                                                                pattern="^\d{6}$"
                                                                title="Please enter a 6-digit Customer ID"
                                                        />

                                                        <div className="button-go" onClick={handleGoButtonClick}>
                                                                {" "}
                                                                →{" "}
                                                        </div>
                                                </div>
                                                <p onClick={handleModalOpen}>Where do I find my customer ID?</p>
                                                <Modal isOpen={isModalOpen} onClose={handleModalClose}>
                                                        <div className="modal-text">
                                                                You can find your Customer ID in the desktop UI by clicking on your helmet icon in the top right corner, then clicking <b>Your Name</b>
                                                        </div>
                                                        <img className="ui_id_img" src={ui_id_img} alt="UI Customer ID location" />
                                                        <div className="modal-text">
                                                                <b>Or</b>, if you use the iRacing Member Site, you can visit your Your Name page and view your Customer ID in the URL
                                                        </div>
                                                        <img className="ui_id_img" src={url_id_img} alt="UI Customer ID location" />
                                                </Modal>
                                                <div className="cs-examples-container">
                                                        <h2>Just Looking?</h2>
                                                        <div className="example_users_container">
                                                                <div className="example_users_text" onClick={() => handleExamples(168966)}>
                                                                        <p>
                                                                                <i>
                                                                                        M. Verstappen <br /> 168966
                                                                                </i>
                                                                        </p>
                                                                </div>
                                                                <div className="example_users_text" onClick={() => handleExamples(95469)}>
                                                                        <p>
                                                                                <i>
                                                                                        J. Broadbent <br /> 95469
                                                                                </i>
                                                                        </p>
                                                                </div>
                                                                <div className="example_users_text" onClick={() => handleExamples(370235)}>
                                                                        <p>
                                                                                <i>
                                                                                        D. Earnhardt Jr <br /> 370235
                                                                                </i>
                                                                        </p>
                                                                </div>
                                                                <div className="example_users_text" onClick={() => handleExamples(444212)}>
                                                                        <p>
                                                                                <i>
                                                                                        T. Kanaan <br /> 444212
                                                                                </i>
                                                                        </p>
                                                                </div>
                                                        </div>
                                                </div>
                                        </div>
                                </div>
                        </div>
                );
        } else {
                return <div className="spinner"></div>;
        }

        function handleCategoryToggle(category) {
                setSelectedCategories((prevSelectedCategories) => {
                        if (prevSelectedCategories.includes(category)) {
                                // Deselect the category, but only if more than one category is selected
                                if (prevSelectedCategories.length > 1) {
                                        return prevSelectedCategories.filter((cat) => cat !== category);
                                } else {
                                        // Optional: Show an alert or message to the user
                                        alert("At least one category must be selected.");
                                        return prevSelectedCategories; // Keep the last category selected
                                }
                        } else {
                                // Select the category
                                return [...prevSelectedCategories, category];
                        }
                });
        }
}

export default CareerStats;
