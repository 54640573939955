import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Plot from "react-plotly.js";
import "../styles/SeriesLapTimeChart.css";
import { useNavigate } from "react-router-dom";
import { getIconFileName } from "../utils/iconUtils";
import { seriesList } from "../utils/seriesList";
import { FormulaCarSeriesKeywords, SportsCarSeriesKeywords, OvalSeriesKeywords, DirtRoadSeriesKeywords, DirtOvalSeriesKeywords } from "../utils/seriesKeywords";
import { carColors } from "../utils/carColors";

function SeriesLapTimeChart() {
        const { season_name } = useParams();
        const [data, setData] = useState([]);
        const [loading, setLoading] = useState(true);
        const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
        const [searchTerm, setSearchTerm] = useState("");
        const [selectedCategory, setSelectedCategory] = useState("");
        const [tableVisibility, setTableVisibility] = useState({});
        const [weekNumber, setWeekNumber] = useState(null);
        const [weekNumberSelected, setWeekNumberSelected] = useState(null);
        const [filteredSeriesList, setFilteredSeriesList] = useState(seriesList);
        const [isQualifying, setIsQualifying] = useState(true);
        const [trackNames, setTrackNames] = useState([]);

        useEffect(() => {
                const checkMobile = () => setIsMobile(window.innerWidth <= 768);
                window.addEventListener("resize", checkMobile);
                return () => window.removeEventListener("resize", checkMobile);
        }, []);

        useEffect(() => {
                setLoading(true);
                async function fetchWeekNumber() {
                        try {
                                const response = await fetch("https://iracing6-backend.herokuapp.com/api/week-number");
                                const json = await response.json();

                                // If `json` is a string, convert it here:
                                const numericWeek = parseInt(json, 10);

                                setWeekNumber(numericWeek);
                                setWeekNumberSelected(numericWeek); // Set selected week number to current week
                                setLoading(false);
                        } catch (error) {
                                console.error("Error fetching week number:", error);
                        }
                }

                fetchWeekNumber();
        }, []);

        useEffect(() => {
                setLoading(true);
                async function fetchSeasonTrackNames() {
                        try {
                                const response = await fetch(`https://iracing6-backend.herokuapp.com/api/series-basic-info/series-basic-info/seasonTracks/${season_name}`);
                                const json = await response.json();

                                const trackNames = json;
                                setTrackNames(trackNames);
                                setLoading(false);
                        } catch (error) {
                                console.error("Error fetching season track names:", error);
                        }
                }

                fetchSeasonTrackNames();
        }, [season_name]);

        useEffect(() => {
                let filtered = seriesList;

                if (selectedCategory) {
                        let categoryKeywords = [];
                        switch (selectedCategory) {
                                case "formula car":
                                        categoryKeywords = FormulaCarSeriesKeywords;
                                        break;
                                case "sports car":
                                        categoryKeywords = SportsCarSeriesKeywords;
                                        break;
                                case "oval":
                                        categoryKeywords = OvalSeriesKeywords;
                                        break;
                                case "dirt road":
                                        categoryKeywords = DirtRoadSeriesKeywords;
                                        break;
                                case "dirt oval":
                                        categoryKeywords = DirtOvalSeriesKeywords;
                                        break;
                                default:
                                        break;
                        }
                        filtered = filtered.filter((series) => categoryKeywords.some((keyword) => series.includes(keyword)));
                }

                filtered = filtered.filter((series) => series.toLowerCase().includes(searchTerm.toLowerCase()));

                setFilteredSeriesList(filtered);
        }, [searchTerm, selectedCategory]);

        useEffect(() => {
                if (weekNumberSelected !== null) {
                        async function fetchData() {
                                try {
                                        let endpoint;

                                        if (weekNumberSelected === weekNumber) {
                                                endpoint = isQualifying
                                                        ? `https://iracing6-backend.herokuapp.com/api/series-avg-lap-times/average-lap-time/${season_name}`
                                                        : `https://iracing6-backend.herokuapp.com/api/series-avg-lap-times/average-race-lap-time/${season_name}`;
                                        } else {
                                                endpoint = isQualifying
                                                        ? `https://iracing6-backend.herokuapp.com/api/series-avg-lap-times/average-lap-time-historic/${season_name}/${weekNumberSelected}`
                                                        : `https://iracing6-backend.herokuapp.com/api/series-avg-lap-times/average-race-lap-time-historic/${season_name}/${weekNumberSelected}`;
                                        }

                                        const response = await fetch(endpoint);
                                        const json = await response.json();

                                        const lapTimes = json.map((item) => item.best_lap_time_avg / 10000);
                                        const Q1 = lapTimes.sort((a, b) => a - b)[Math.floor(lapTimes.length / 4)];
                                        const Q3 = lapTimes.sort((a, b) => a - b)[Math.floor((3 * lapTimes.length) / 4)];
                                        const IQR = Q3 - Q1;
                                        const lowerBound = Q1 - 2 * IQR;
                                        const upperBound = Q3 + 2 * IQR;

                                        const filteredData = json.filter((item) => {
                                                const lapTime = item.best_lap_time_avg / 10000;
                                                return lapTime >= lowerBound && lapTime <= upperBound;
                                        });

                                        const sortedData = filteredData.sort((a, b) => a.i_rating_range[0] - b.i_rating_range[0]);

                                        setData(sortedData);
                                        setLoading(false);

                                        const initialTableVisibility = {};
                                        sortedData.forEach((item) => {
                                                const className = item.compositeKey.carClassName;
                                                if (!initialTableVisibility[className]) {
                                                        initialTableVisibility[className] = false;
                                                }
                                        });
                                        setTableVisibility(initialTableVisibility);
                                } catch (error) {
                                        console.error("Error fetching data:", error);
                                        setLoading(false);
                                }
                        }

                        fetchData();
                }
        }, [season_name, isQualifying, weekNumberSelected]);

        const navigate = useNavigate();

        const handleLinkToSeriesPop = () => {
                navigate("/charts/SeriesPopularity");
        };

        const getIconUrl = (seasonName) => {
                const iconName = getIconFileName(seasonName);
                return `${process.env.PUBLIC_URL}/assets/series-icons/${iconName}.png`;
        };

        const handleLinkToSpecificSeries = (seriesName) => {
                const index = seriesList.indexOf(seriesName);
                const encodedSeriesName = encodeURIComponent(seriesList[index]);
                navigate(`/charts/SeriesLapTimeChart/${encodedSeriesName}`);
        };

        const toggleTableVisibility = (className) => {
                setTableVisibility((prevState) => ({
                        ...prevState,
                        [className]: !prevState[className],
                }));
        };

        if (loading || weekNumberSelected === null) {
                return <div className="spinner"></div>;
        }

        const groupedData = data.reduce((acc, item) => {
                const className = item.compositeKey.carClassName;
                if (!acc[className]) {
                        acc[className] = [];
                }
                acc[className].push(item);
                return acc;
        }, {});

        const plotData = () => {
                const allXValues = data.flatMap((item) => item.i_rating_range);
                const minX = Math.min(...allXValues);
                const maxX = Math.max(...allXValues);
                const xValues = [];
                for (let x = minX; x <= maxX; x += 100) {
                        xValues.push(x);
                }

                return Object.keys(groupedData).map((className) => {
                        const classData = groupedData[className];
                        const yValues = xValues.map((x) => {
                                const item = classData.find((item) => x >= item.i_rating_range[0] && x <= item.i_rating_range[1]);
                                return item ? item.best_lap_time_avg / 10000 : null;
                        });

                        // Filter out leading and trailing null values to prevent gaps at the ends
                        const firstNonNullIndex = yValues.findIndex((value) => value !== null);
                        const lastNonNullIndex = yValues.length - 1 - [...yValues].reverse().findIndex((value) => value !== null);
                        const trimmedXValues = xValues.slice(firstNonNullIndex, lastNonNullIndex + 1);
                        const trimmedYValues = yValues.slice(firstNonNullIndex, lastNonNullIndex + 1);

                        return {
                                x: trimmedXValues,
                                y: trimmedYValues,
                                type: "scatter",
                                mode: "lines+markers",
                                marker: { color: carColors[className] || "#f1c40f" },
                                name: className,
                                connectgaps: true,
                        };
                });
        };

        const boxPlotData = () => {
                // Step 1: Compute the median lap time for each car class
                const classStats = Object.keys(groupedData).map((className) => {
                        const classData = groupedData[className];
                        const lapTimes = classData.map((item) => item.best_lap_time_avg / 10000);
                        const sortedLapTimes = lapTimes.slice().sort((a, b) => a - b);
                        const mid = Math.floor(sortedLapTimes.length / 2);
                        const median = sortedLapTimes.length % 2 !== 0 ? sortedLapTimes[mid] : (sortedLapTimes[mid - 1] + sortedLapTimes[mid]) / 2;
                        return {
                                className,
                                lapTimes,
                                median,
                        };
                });

                // Step 2: Sort the car classes from highest to lowest median lap time
                classStats.sort((a, b) => b.median - a.median);

                // Step 3: Generate the box plot data using the sorted car classes
                return classStats.map(({ className, lapTimes }) => ({
                        y: lapTimes,
                        type: "box",
                        name: className,
                        marker: { color: carColors[className] || "#f1c40f" },
                        boxpoints: false,
                        boxWidth: 0.5,
                }));
        };

        const layout = {
                font: {
                        family: "Questrial, sans-serif",
                        size: window.innerWidth < 1200 ? 12 : 16, // Increased font size
                        color: "#dddddd",
                },
                margin: window.innerWidth > 1200 ? { l: 80, r: 40, t: 30, b: 120 } : { l: 34, r: 6, t: 10, b: 100 },
                plot_bgcolor: "#25242770",
                paper_bgcolor: "#25242770",
                xaxis: {
                        title: {
                                text: "iRating",
                                font: {
                                        size: window.innerWidth < 1200 ? 14 : 18, // Increased axis title font size
                                },
                        },
                        color: "rgba(180, 180, 180, 1)",
                        tick0: 0,
                        dtick: 500,
                        tickfont: {
                                size: window.innerWidth < 1200 ? 10 : 14, // Increased tick label font size
                        },
                        title_standoff: 0,
                },
                yaxis: {
                        title: {
                                text: isQualifying ? "Avg Quali Time (seconds)" : "Avg Best Race Lap Time (seconds)",
                                font: {
                                        size: window.innerWidth < 1200 ? 14 : 18, // Increased axis title font size
                                },
                        },
                        color: "rgba(180, 180, 180, 1)",
                        tickfont: {
                                size: window.innerWidth < 1200 ? 10 : 14, // Increased tick label font size
                        },
                },
                hovermode: false,
                legend: {
                        x: 0.5,
                        y: window.innerWidth < 1200 ? -0.22 : -0.15,
                        xanchor: "center",
                        yanchor: "top",
                        orientation: "h",
                        font: {
                                size: window.innerWidth < 1200 ? 10 : 15, // Increased legend font size
                        },
                },
                width: window.innerWidth > 1200 ? window.innerWidth - 100 : window.innerWidth - 20,
                height: window.innerWidth > 1200 ? 600 : window.innerHeight - 300,
        };

        const boxLayout = {
                ...layout,
                margin: window.innerWidth > 1200 ? { l: 80, r: 40, t: 0, b: 120 } : { l: 30, r: 40, t: 0, b: 140 },
                height: window.innerWidth > 1200 ? 500 : 300,
                showlegend: false,
                xaxis: {
                        title: {
                                text: "",
                                font: {
                                        size: window.innerWidth < 1200 ? 14 : 18, // Increased axis title font size
                                },
                        },
                        color: "rgba(180, 180, 180, 1)",
                        tickfont: {
                                size: window.innerWidth < 1200 ? 9 : 14, // Increased tick label font size
                        },
                        tickangle: isMobile ? -90 : 0, // Rotate labels on mobile
                },
        };

        if (!season_name) {
                return (
                        <div>
                                <div className="slp-container">
                                        <div className="slp-title">Series Lap Times</div>
                                        <div className="slp-pop-cont" onClick={handleLinkToSeriesPop}>
                                                Select Series by Popularity Here 🡵
                                        </div>

                                        <div className="slp-or-div">Or, click a series below:</div>
                                        <div className="chart-cat-buttons-sp-container">
                                                <button className="chart-cat-buttons-sp-div-le" onClick={() => setSelectedCategory("sports car")}>
                                                        Sports Car
                                                </button>
                                                <button className="chart-cat-buttons-sp-div" onClick={() => setSelectedCategory("formula car")}>
                                                        Formula Car
                                                </button>
                                                <button className="chart-cat-buttons-sp-div" onClick={() => setSelectedCategory("oval")}>
                                                        Oval
                                                </button>
                                                <button className="chart-cat-buttons-sp-div" onClick={() => setSelectedCategory("dirt road")}>
                                                        Dirt Road
                                                </button>
                                                <button className="chart-cat-buttons-sp-div-re" onClick={() => setSelectedCategory("dirt oval")}>
                                                        Dirt Oval
                                                </button>
                                        </div>
                                        <div className="search-bar-container-slt">
                                                <input type="text" className="search-bar" placeholder="Search series..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                                        </div>
                                        <div className="slp-series-squares-container">
                                                {filteredSeriesList.map((series, index) => (
                                                        <div key={index} className="slp-series-square" onClick={() => handleLinkToSpecificSeries(series)}>
                                                                <div className="slp-series-icon">
                                                                        <img
                                                                                src={getIconUrl(series)}
                                                                                style={{
                                                                                        marginRight: "0.3rem",
                                                                                        marginLeft: "0.3rem",
                                                                                        width: "1.9rem",
                                                                                        marginTop: "0rem",
                                                                                        marginBottom: "0rem",
                                                                                }}
                                                                        />
                                                                </div>
                                                                <div className="slp-series-name">{series}</div>
                                                        </div>
                                                ))}
                                        </div>
                                </div>
                        </div>
                );
        }

        if (season_name) {
                const uniqueCarClasses = Object.keys(groupedData);

                return (
                        <div>
                                <div className="slp-season-name">
                                        {season_name} (Week {weekNumberSelected}/13)
                                </div>
                                <div className="slp-track-names">{trackNames[weekNumberSelected - 1]}</div>
                                <div className="sltc-button-choice-cont">
                                        <button className={isQualifying ? "sltc-choice-active" : "sltc-choice-inactive"} onClick={() => setIsQualifying(true)}>
                                                Qualifying
                                        </button>
                                        <button className={!isQualifying ? "sltc-choice-active" : "sltc-choice-inactive"} onClick={() => setIsQualifying(false)}>
                                                Race
                                        </button>
                                        <div className="sltc-week-number-label">Week:</div>
                                        <select className="sltc-week-number-input" value={weekNumberSelected || ""} onChange={(e) => setWeekNumberSelected(parseInt(e.target.value))}>
                                                {Array.from({ length: weekNumber }, (_, i) => (
                                                        <option key={i + 1} value={i + 1}>
                                                                {i + 1}
                                                        </option>
                                                ))}
                                        </select>
                                        <div className="sltc-week-number-beta-tag">(beta)</div>
                                </div>
                                <Plot data={plotData()} layout={layout} config={isMobile ? { displayModeBar: false } : { displayModeBar: false }} />
                                {uniqueCarClasses.length > 1 && (
                                        <div>
                                                <Plot data={boxPlotData()} layout={boxLayout} config={isMobile ? { displayModeBar: false } : { displayModeBar: false }} />
                                        </div>
                                )}
                                {uniqueCarClasses.map((className) => (
                                        <div key={className} className="centering-div-pure">
                                                <div className="table-toggle-button" onClick={() => toggleTableVisibility(className)}>
                                                        {tableVisibility[className] ? "Hide Table" : `Show Table ${className}`}
                                                </div>
                                                {tableVisibility[className] && (
                                                        <div className="data-table-container-sp">
                                                                <table className="data-table-sp">
                                                                        <thead>
                                                                                <tr className="tr-sp-title">
                                                                                        <th>iRating Range</th>
                                                                                        <th>{isQualifying ? "Avg Quali Time (seconds)" : "Avg Best Race Lap Time (seconds)"}</th>
                                                                                </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                                {groupedData[className].map((item, index) => (
                                                                                        <tr key={index}>
                                                                                                <td>{item.i_rating_range.join("-")}</td>
                                                                                                <td>{(item.best_lap_time_avg / 10000).toFixed(4)}</td>
                                                                                        </tr>
                                                                                ))}
                                                                        </tbody>
                                                                </table>
                                                        </div>
                                                )}
                                        </div>
                                ))}
                        </div>
                );
        }
}

export default SeriesLapTimeChart;
